import React from 'react';
import logo from './logo.svg';
import Dropzone from './components/Dropzone';

function App() {
  return (
    <div className="min-h-screen container max-w-4xl lg:max-w-6xl 2xl:max-w-7xl mx-auto">
      <div className="p-4">
        <div className="flex justify-center">
          <img src="./splash-logo.svg" alt="" className="w-28 h-28" />
        </div>

        <div className="space-y-6">
          <h1 className="text-3xl md:text-5xl font-medium text-center">Splash File Converter</h1>
          <p className="text-muted-foreground text-md md:text-lg text-center md:px-24 xl:px-44 2xl:px-52">
            Transform images, audio, and videos effortlessly, without restrictions. Start converting now and elevate your content like never
            before!
          </p>
        </div>
        <Dropzone />
      </div>
    </div>
  );
}

export default App;
